<template>
    <v-menu
      v-model="menu"
      ref="menu1"
      transition="scale-transition"
      offset-y
      max-width="290px"
      :close-on-content-click="false"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :label="text"
        v-model="date"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        required
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      @change="menu = false"
      :min="minDate"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  data () {
    return {
      date: this.dateDefault,
      menu: false,
    }
  },
  props: {
    dateDefault: String,
    text: String,
    minDate: {
      type: String,
      default: '',
    },
  },
  watch: {
    date () {
      this.$emit('date-selected', this.date)
    },
  },
}
</script>