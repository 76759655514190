<template>
  <v-btn
    class="white--text"
    color="pink darken-1"
    block @click="search"
    :loading="loading"
    :disabled="loading"
  >
  {{text}}
  </v-btn>
</template>

<script>
export default {
  props: {
    search: Function,
    text: String,
    loading: Boolean,
  },
}
</script>
