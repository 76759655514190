<template>
    <v-text-field :label="text" v-model="email" :rules="emailRules" ></v-text-field>
</template>

<script>
export default {
  data () {
    return {
      email: this.modelValue,
      emailRules: [
        v => !!v || this.$t('input.required'),
        v => /.+@.+/.test(v) || this.$t('input.emailInvalid'),
      ],
    }
  },
  props: {
    text: String,
    modelValue: String,
  },
  watch: {
    email () {
      this.$emit('email-value', this.email)
    },
  },
}
</script>