<template>
  <v-row class="px-4 pt-8 blue-grey lighten-5">
    <v-col>
      <v-row class="px-4">
        <v-col class="pb-0 px-2" cols="12" md="4">
          <FormInput :text="emailLabel" @email-value="selectedEmail" v-model="modelValue" />
        </v-col>
        <v-col class="pb-0 px-2" cols="12" md="3">
          <DatePicker :dateDefault="date" :text="startDateLabel" @date-selected="selectedStartDate" />
        </v-col>
        <v-col class="pb-0 px-2" cols="12" md="3">
          <DatePicker :dateDefault="date" :text="endDateLabel" @date-selected="selectedEndDate" :minDate="valuesSelected.startDate" />
        </v-col>
        <v-col class="pb-0 px-2" cols="12" md="2">
          <FormButton :search="triggerAction" :text="triggerLabel" :loading="loadingProp" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import DatePicker from './selects/DatePicker.vue'
import FormButton from './buttons/FormButton'
import FormInput from './inputs/FormInput.vue'
export default {
  components: {
    DatePicker,
    FormButton,
    FormInput,
  },
  data () {
    return {
      valuesSelected : {
        email: '',
        startDate: this.date,
        endDate: this.date,
      },
      emailLabel: this.$t('input.emailLabel'),
      triggerLabel: this.$t('button.triggerLabel'),
      startDateLabel: this.$t('datePicker.startDateLabel'),
      endDateLabel: this.$t('datePicker.endDateLabel'),
    }
  },
  props: {
    date: String,
    loadingProp: Boolean,
    modelValue: String,
    triggerMethod: Function,
  },
  methods: {
    selectedEmail (params) {
      this.valuesSelected.email = params
    },
    selectedStartDate (params) {
      this.valuesSelected.startDate = params
    },
    selectedEndDate (params) {
      this.valuesSelected.endDate = params
    },
    triggerAction () {
      this.$emit('trigger-action', this.valuesSelected)
    },
  },
}
</script>
